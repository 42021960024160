<template>
  <a-layout-sider :width="sidebarWidth" class="admin-second-sidebar">
    <div class="resize-handle" @mousedown="startResize"></div>
    <admin-org-selector />
    <a-menu
      mode="inline"
      :defaultSelectedKeys="['1']"
      style="height: calc(100% - 65px)"
      :selectedKeys="[$route.path]"
    >
      <!-- Start Here -->
      <a-sub-menu key="start-here">
        <template #title>
          <span>
            <a-icon type="home" />
            <span>Start Here</span>
          </span>
        </template>
        <a-menu-item key="/admin">
          <router-link to="/admin">Getting Started</router-link>
        </a-menu-item>
        <!-- <a-menu-item key="/admin/old">
          <router-link to="/admin/old">Admin Old</router-link>
        </a-menu-item> -->
        <!-- <a-menu-item key="/admin/stats">
          <router-link to="/admin/stats">Stats</router-link>
        </a-menu-item> -->
      </a-sub-menu>

      <!-- Configuration -->
      <a-sub-menu key="configuration">
        <template #title>
          <span>
            <a-icon type="setting" />
            <span>Configuration</span>
          </span>
        </template>
        <a-menu-item key="/admin/response-teams">
          <router-link to="/admin/response-teams">Response Teams</router-link>
        </a-menu-item>
        
        <a-menu-item key="/admin/managed-groups">
          <router-link to="/admin/managed-groups">Managed Groups</router-link>
        </a-menu-item>
        
        <a-sub-menu key="incident-management">
          <template #title>Incident Management</template>
          <a-menu-item key="/admin/incident-types">
            <router-link to="/admin/incident-types">Incident Types</router-link>
          </a-menu-item>
          <a-menu-item key="/admin/emergency-types">
            <router-link to="/admin/emergency-types">Emergency Types</router-link>
          </a-menu-item>
          <!-- <a-menu-item key="/admin/incident-type-groups">
            <router-link to="/admin/incident-type-groups">Incident Type Groups</router-link>
          </a-menu-item> -->
        </a-sub-menu>

        <a-sub-menu key="guidance">
          <template #title>Guidance</template>
          <a-menu-item key="/admin/guidance/topics">
            <router-link to="/admin/guidance/topics">Topics</router-link>
          </a-menu-item>
          <a-menu-item key="/admin/guidance/articles">
            <router-link to="/admin/guidance/articles">Articles</router-link>
          </a-menu-item>
          <a-menu-item key="/admin/guidance/help-videos">
            <router-link to="/admin/guidance/help-videos">Help Videos</router-link>
          </a-menu-item>
          <!-- <a-menu-item key="/admin/guidance/playbooks">
            <router-link to="/admin/guidance/playbooks">Playbooks</router-link>
          </a-menu-item> -->
          <a-menu-item key="/admin/settings/playbooks">
            <router-link to="/admin/settings/playbooks">Playbooks</router-link>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="templates">
          <template #title>Templates</template>
          <a-menu-item key="/admin/templates/announcement-templates">
            <router-link to="/admin/templates/announcement-templates">Announcement Templates</router-link>
          </a-menu-item>
          <a-menu-item key="/admin/templates/locations">
            <router-link to="/admin/templates/locations">Locations</router-link>
          </a-menu-item>
          <a-menu-item key="/admin/templates/quick-messages">
            <router-link to="/admin/templates/quick-messages">Quick Messages</router-link>
          </a-menu-item>
          <a-menu-item key="/admin/templates/quick-incident-responses">
            <router-link to="/admin/templates/quick-incident-responses">Quick Incident Responses</router-link>
          </a-menu-item>
        </a-sub-menu>
      </a-sub-menu>

      <!-- User Management -->
      <a-sub-menu key="user-management">
        <template #title>
          <span>
            <a-icon type="team" />
            <span>User Management</span>
          </span>
        </template>
        <a-menu-item key="/admin/users/invite">
          <router-link to="/admin/users/invite">Invite New Users</router-link>
        </a-menu-item>
        <a-menu-item key="/admin/users/imports">
          <router-link to="/admin/users/imports">Import Users</router-link>
        </a-menu-item>
        <a-menu-item key="/admin/users/manage">
          <router-link to="/admin/users/manage">Manage Users</router-link>
        </a-menu-item>
      </a-sub-menu>

      <!-- Reporting -->
      <a-sub-menu key="reporting">
        <template #title>
          <span>
            <a-icon type="bar-chart" />
            <span>Reporting</span>
          </span>
        </template>
        <a-menu-item key="/admin/reporting">
          <router-link to="/admin/reporting">Reports</router-link>
        </a-menu-item>
        <a-menu-item key="/admin/report-by-tag">
          <router-link to="/admin/report-by-tag">Report By Tag</router-link>
        </a-menu-item>
        <a-menu-item key="/admin/stats">
          <router-link to="/admin/stats">Statistics</router-link>
        </a-menu-item>
      </a-sub-menu>

      <!-- Incidents -->
      <a-sub-menu key="incidents">
        <template #title>
          <span>
            <a-icon type="alert" />
            <span>Incidents</span>
          </span>
        </template>
        <a-menu-item key="/admin/incidents">
          <router-link to="/admin/incidents">Open Incidents</router-link>
        </a-menu-item>
        <a-menu-item key="/admin/archived-incidents">
          <router-link to="/admin/archived-incidents">Closed Incidents</router-link>
        </a-menu-item>
      </a-sub-menu>

      <!-- Announcements -->
      <a-sub-menu key="announcements">
        <template #title>
          <span>
            <a-icon type="notification" />
            <span>Announcements</span>
          </span>
        </template>
        <a-menu-item key="/admin/announcements">
          <router-link to="/admin/announcements">Announcements</router-link>
        </a-menu-item>
      </a-sub-menu>

      <!-- Technical Set Up -->
      <a-sub-menu key="technical-setup">
        <template #title>
          <span>
            <a-icon type="tool" />
            <span>Technical Set Up</span>
          </span>
        </template>
        <a-menu-item key="/admin/settings/windows-installs">
          <router-link to="/admin/settings/windows-installs">Window Installs</router-link>
        </a-menu-item>
        
        <a-sub-menu key="integrations">
          <template #title>Integrations</template>
          <a-menu-item key="/admin/integration/smart-buttons">
            <router-link to="/admin/integration/smart-buttons">Smart Buttons</router-link>
          </a-menu-item>
          <a-menu-item key="/admin/integration/partners">
            <router-link to="/admin/integration/partners">Partners</router-link>
          </a-menu-item>
          <a-menu-item key="/admin/integration/api-clients">
            <router-link to="/admin/integration/api-clients">API Clients</router-link>
          </a-menu-item>
        </a-sub-menu>
        
        <a-menu-item key="/admin/organisational-settings">
          <router-link to="/admin/organisational-settings">Organisational Settings</router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import AdminOrgSelector from './AdminOrgSelector.vue';

export default {
  name: 'AdminSecondSidebar',
  components: {
    AdminOrgSelector
  },
  data() {
    return {
      sidebarWidth: 250,
      isResizing: false,
      startX: 0,
      startWidth: 250
    };
  },
  methods: {
    startResize(e) {
      this.isResizing = true;
      this.startX = e.clientX;
      this.startWidth = this.sidebarWidth;
      
      document.addEventListener('mousemove', this.handleResize);
      document.addEventListener('mouseup', this.stopResize);
    },
    
    handleResize(e) {
      if (!this.isResizing) return;
      
      const diff = e.clientX - this.startX;
      const newWidth = this.startWidth + diff;
      
      // Set minimum and maximum width constraints
      if (newWidth >= 200 && newWidth <= 400) {
        this.sidebarWidth = newWidth;
      }
    },
    
    stopResize() {
      this.isResizing = false;
      document.removeEventListener('mousemove', this.handleResize);
      document.removeEventListener('mouseup', this.stopResize);
    }
  }
};
</script>

<style scoped>
.admin-second-sidebar {
  background: #fff;
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  overflow-y: auto;
  height: calc(100vh - 64px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  position: relative;
  user-select: none;
}

.resize-handle {
  position: absolute;
  right: -5px;
  top: 0;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
  z-index: 100;
}

.resize-handle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

:deep(.ant-menu) {
  border-right: none;
}

:deep(.ant-menu-item) {
  margin: 4px 0;
  /* border-radius: 6px; */
  margin-right: 8px;
}

:deep(.ant-menu-item) a {
  color: #595959;
  font-size: 14px;
}

:deep(.ant-menu-item-selected) {
  background-color: #f0f5ff !important;
}

:deep(.ant-menu-item-selected) a {
  color: #1890ff;
}

:deep(.ant-menu-submenu-title) {
  color: #262626;
  font-size: 14px;
  height: 45px !important;
  line-height: 45px !important;
}

/* Style for only the main top-level categories */
:deep(.ant-menu > .ant-menu-submenu[key="start-here"] > .ant-menu-submenu-title),
:deep(.ant-menu > .ant-menu-submenu[key="configuration"] > .ant-menu-submenu-title),
:deep(.ant-menu > .ant-menu-submenu[key="user-management"] > .ant-menu-submenu-title),
:deep(.ant-menu > .ant-menu-submenu[key="reporting"] > .ant-menu-submenu-title),
:deep(.ant-menu > .ant-menu-submenu[key="incidents"] > .ant-menu-submenu-title),
:deep(.ant-menu > .ant-menu-submenu[key="announcements"] > .ant-menu-submenu-title),
:deep(.ant-menu > .ant-menu-submenu[key="technical-setup"] > .ant-menu-submenu-title) {
  font-weight: 600;
}

:deep(.ant-menu-item:hover),
:deep(.ant-menu-submenu-title:hover) {
  color: #1890ff;
  background-color: #f5f5f5;
}

:deep(.ant-menu-submenu-selected > .ant-menu-submenu-title) {
  color: #1890ff !important;
}

:deep(.ant-menu-sub) {
  padding-left: 8px;
}

:deep(.ant-menu-item .anticon),
:deep(.ant-menu-submenu-title .anticon) {
  font-size: 16px;
  margin-right: 10px;
}

:deep(.ant-menu-item),
:deep(.ant-menu-submenu) {
  transition: all 0.2s ease;
}
</style> 