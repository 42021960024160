<template>
  <div class="admin-org-selector">
    <a-select
      v-model="selected_organisation"
      style="width: 100%"
      :loading="loading"
      show-search
      :filter-option="filterOption"
      option-filter-prop="children"
    >
      <a-select-option
        v-for="org in organisationsIAmAnAdminFor"
        :key="org.id"
        :value="org.id"
        :label="getOrganisationDisplayName(org)"
      >
        <span role="img">
          <organisation-thumbnail
            :tenant-id="getOrgTenantId(org)"
            :org-id="org.id"
            style="display: inline; margin-right: 13px"
            size="small"
            :key="selectedOrganisation ? selectedOrganisation.id : org.id"
          />
        </span>
        {{ getOrganisationDisplayName(org) }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrganisationThumbnail from '../Organisations/OrganisationThumbnail.vue';
import Organisations from '../../mixins/Organisations';
import organisationHelpers from '../../helpers/organisations';
const _ = require('lodash');

export default {
  name: 'AdminOrgSelector',
  components: { OrganisationThumbnail },
  mixins: [Organisations],
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      organisationsIAmAnAdminFor: 'missionControl/organisationsIAmAnAdminFor',
      selectedOrganisation: 'admin/selectedOrganisation',
      lastOrgId: 'subjectPreferences/lastOrgId'
    }),
    selected_organisation: {
      get() {
        return this.selectedOrganisation ? this.selectedOrganisation.id : null;
      },
      async set(val) {
        await this.selectOrganisation(
          _.find(this.organisationsIAmAnAdminFor, { id: val })
        );
        // After organization is changed, redirect to /admin
        await this.$router.push('/admin');
      }
    }
  },
  created() {
    if (this.organisationsIAmAnAdminFor.length && !this.selectedOrganisation) {
      // Attempt to get from subject preferences, otherwise select first org
      if (
        this.lastOrgId &&
        _.map(this.organisationsIAmAnAdminFor, "id").includes(this.lastOrgId)
      ) {
        this.selectOrganisation(
          _.find(this.organisationsIAmAnAdminFor, { id: this.lastOrgId })
        );
      } else {
        this.selectOrganisation(_.first(this.organisationsIAmAnAdminFor));
      }
    }
  },
  methods: {
    ...mapActions('admin', {
      selectOrganisation: 'selectOrganisation'
    }),
    getOrgTenantId(org) {
      return organisationHelpers.getOrganisationTenantId(org);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>

<style scoped>
.admin-org-selector {
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  background: #fff;
}

:deep(.ant-select) {
  font-size: 14px;
}

:deep(.ant-select-selector) {
  border-radius: 4px;
}

:deep(.ant-select-selection-item) {
  display: flex;
  align-items: center;
}
</style> 