<template>
  <a-layout class="admin-layout">
    <admin-second-sidebar />

    <a-layout>
      <a-layout-content style="margin: 0 10px; padding: 20px; min-height: 280px">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import AdminSecondSidebar from '../components/Admin/AdminSecondSidebar.vue';

export default {
  name: 'AdminLayout',
  components: {
    AdminSecondSidebar
  }
};
</script>

<style scoped>
.admin-layout {
  /* min-height: 100vh; */
  height: calc(100vh - 64px);
}


.logo {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.logo img {
  width: 100%;
  height: auto;
}
</style> 