import incidentTypes from "../../api/incident-types";
const _ = require('lodash')
import organisations from "../../helpers/organisations";
import incidents from "../../api/incidents";
import timelines from "../../api/timelines";
import router from '../../router';
import eventBus from "../../event-bus";
import tagsApi from "../../api/tags";

// import moment from 'moment-timezone';

export const state = {
    serverErrors: [],
    isSaving: false,
    selectedOrganisation: null,

    isLoadingIncidentTypes: false,
    incidentTypes: [],

    isDemo: true
};

export const mutations = {
    SET_SELECTED_ORGANISATION(state, organisation) {
        state.selectedOrganisation = organisation
    },

    START_LOADING_INCIDENT_TYPES(state) {
        state.isLoadingIncidentTypes = true
    },

    STOP_LOADING_INCIDENT_TYPES(state) {
        state.isLoadingIncidentTypes = false
    },

    SET_INCIDENT_TYPES(state, incidentTypes) {
        state.incidentTypes = incidentTypes
    },

    START_SAVING(state) {
        state.isSaving = true
    },

    STOP_SAVING(state) {
        state.isSaving = false
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors
    },

    SET_IS_DEMO(state, isDemo) {
        state.isDemo = isDemo
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoadingIncidentTypes
    },

    isLoadingIncidentTypes: (state) => {
        return state.isLoadingIncidentTypes
    },

    isSaving: (state) => {
        return state.isSaving
    },

    selectedOrganisation: (state) => {
        return state.selectedOrganisation
    },

    incidentTypes: (state) => {
        return state.incidentTypes
    },

    sosIncidentTypes: (state) => {
        return _.filter(state.incidentTypes, incidentType => {
            return incidentType.purpose === 1;
        })
    },

    otherIncidentTypes: (state) => {
        return _.filter(state.incidentTypes, incidentType => {
            return incidentType.purpose !== 1;
        })
    },

    numberOfIncidentTypesShown: (state, getters) => {
        return getters.sosIncidentTypes.length + _.filter(getters.otherIncidentTypes, it => {
            return it.incidentTypeGroupId;
        }).length;
    },

    isDemoModeOff: (state) => {
        if (state.selectedOrganisation) {
            let demoMode = state.selectedOrganisation['content']['$v']['demoMode'];
            return demoMode == null;
        }
        return false;
    },

    isDemoModeNone: (state) => {
        if (state.selectedOrganisation) {
            let demoMode = state.selectedOrganisation['content']['$v']['demoMode'];
            return demoMode == 0;
        }
        return false;
    },

    isDemoModeOptional: (state) => {
        if (state.selectedOrganisation) {
            let demoMode = state.selectedOrganisation['content']['$v']['demoMode'];
            return demoMode == 1;
        }
        return false;
    },

    isDemoModeAlways: (state) => {
        if (state.selectedOrganisation) {
            let demoMode = state.selectedOrganisation['content']['$v']['demoMode'];
            return demoMode == 2;
        }
        return false;
    },

    isDemo: (state, getters) => {
        if (getters.isDemoModeAlways) {
            return true;
        }
        if (getters.isDemoModeOptional) {
            return state.isDemo;
        }
        if (getters.isDemoModeNone) {
            return false;
        }
        return false;
    }
};

export const actions = {
    selectOrganisation({ commit, dispatch }, organisation) {
        commit('SET_SELECTED_ORGANISATION', organisation)

        // console.log('Organisation:');
        // console.log(organisation);
        // console.log('Organisation Demo Mode:');
        // console.log(organisation['content']['$v']['demoMode']);

        dispatch('loadIncidentTypes')
    },

    loadIncidentTypes({ commit, getters }) {
        commit('START_LOADING_INCIDENT_TYPES');
        incidentTypes.getIncidentTypes(
            organisations.getOrganisationTenantId(getters.selectedOrganisation),
            getters.selectedOrganisation.id
        ).then(r => {
            commit('STOP_LOADING_INCIDENT_TYPES');
            commit('SET_INCIDENT_TYPES', r.data)
        }).catch(e => {
            commit('STOP_LOADING_INCIDENT_TYPES');
            // this._vm.$message.error('Error loading incident types');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        })
    },

    async callForHelp({ commit, getters, dispatch }, params) {
        commit('START_SAVING');

        let { description, incidentType, isDemo, title, tags } = params;
        let presence = await dispatch('missionControl/getPresenceForOrganisationId', getters.selectedOrganisation.id, { root: true })

        // Get the current time
        let currentTime = new Date();

        // Extract date components
        let year = currentTime.getFullYear();
        let month = ('0' + (currentTime.getMonth() + 1)).slice(-2);
        let day = ('0' + currentTime.getDate()).slice(-2);

        // Extract time components
        let hours = ('0' + currentTime.getHours()).slice(-2);
        let minutes = ('0' + currentTime.getMinutes()).slice(-2);
        let seconds = ('0' + currentTime.getSeconds()).slice(-2);
        let milliseconds = ('00' + currentTime.getMilliseconds()).slice(-3);

        // Construct the final string
        let finalString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}\u002B01 Europe/London`;

        console.log(finalString);

        let incidentDetails = {
            OwnerId: getters.selectedOrganisation.id,
            CreatedBy: presence.id,
            DisplayName: title || incidentType.displayName,
            Content: {
                '$t': "Soteria.Models.IncidentContent, Soteria.Models",
                '$v': {
                    IncidentDescription: description,
                    IncidentPurpose: incidentType.purpose,
                    IncidentTypeId: incidentType.id,
                    Mode: (isDemo ? 2 : 0),
                }
            },
            IncidentTimestamp: finalString
        }

        try {
            // Create the incident
            const r = await incidents.createIncident(
                organisations.getOrganisationTenantId(getters.selectedOrganisation),
                incidentDetails
            );
            
            let incident = r.data;
            let organisation = await dispatch('missionControl/getOrganisationById', incident.ownerId, { root: true });
            let tenantId = organisations.getOrganisationTenantId(organisation);

            // Add timeline entry
            await timelines.postTimelineEntry(tenantId, {
                OwnerType: 3,
                OwnerId: r.data.id,
                CreatedByPresenceId: presence.id,
                Content: {
                    $t: "Soteria.Models.MarkdownChatEntry, Soteria.Models",
                    $v: {
                        Text: description
                    }
                }
            });

            let hasLocationTag = false;

            // Add location timeline entry if location tag exists
            if (tags && Array.isArray(tags)) {
                const locationTag = tags.find(tag => tag.type === 'Location');
                if (locationTag) {
                    hasLocationTag = true;
                    await timelines.postTimelineEntry(tenantId, {
                        OwnerType: 3,
                        OwnerId: r.data.id,
                        CreatedByPresenceId: presence.id,
                        Content: {
                            $t: "Soteria.Models.MarkdownChatEntry, Soteria.Models",
                            $v: {
                                Text: `My location: ${locationTag.description}`
                            }
                        }
                    });
                }
            }

            // Add tags if they exist
            if (tags && Array.isArray(tags)) {
                const tagPromises = tags.map(tag => 
                    tagsApi.addTagToGroup(
                        tenantId,
                        getters.selectedOrganisation.id,
                        'Incident',
                        incident.id,
                        tag.provider,
                        tag.type,
                        tag.providersId,
                        tag.description
                    )
                );
                await Promise.all(tagPromises);
            }

            // Rest of the existing code for navigation etc...
            if (!params.dontNavigate) {
                dispatch('incidents/pushIncident', incident, { root: true });
                dispatch('incidentViewer/setIncident', {
                    incident,
                    tenantId
                }, { root: true });

                window.justCreatedIncident = true;
                window.locationAlreadyProvided = hasLocationTag;
                eventBus.$emit('incident-created');

                if (params.lightspeed && params.lightspeed == true) {
                    router.push('/lightspeed/incidents/' + tenantId + '/' + incident.id);
                } else if (params.sims && params.sims == true) {
                    router.push('/sims/incidents/' + tenantId + '/' + incident.id);
                } else {
                    router.push('/incidents/' + tenantId + '/' + incident.id);
                }
            }

            commit('STOP_SAVING');
        } catch (e) {
            commit('STOP_SAVING');
            this._vm.$message.error('Error creating incident');

            console.log(e);
            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        }
    },

    setIsDemo({ commit }, isDemo) {
        commit('SET_IS_DEMO', isDemo);
    }
};