import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/Landing.vue'
import LoggedOut from '../views/LoggedOut.vue'
import Dashboard from '../views/Dashboard.vue'
import AdminLayout from '../layouts/AdminLayout.vue'
import idsrvAuth from '../idsrvAuth'
import store from "../store"
import Admin from '../views/Admin.vue'

console.log(idsrvAuth.authName);

Vue.use(VueRouter)

const adminRoutes = {
  path: '/admin',
  component: AdminLayout,
  meta: {
    authName: idsrvAuth.authName,
    admin: true
  },
  children: [
    {
      path: '',
      name: 'Admin Dashboard',
      component: Admin
    },
    {
      path: 'old',
      name: 'Admin Old',
      component: () => import(/* webpackChunkName: "admin-old" */ '../views/AdminOld.vue')
    },
    // Configuration
    {
      path: 'response-teams',
      name: 'Response Teams',
      component: () => import(/* webpackChunkName: "admin-response-teams" */ '../views/Admin/Responders/ResponseTeams.vue')
    },
    // Incident Management
    {
      path: 'incident-types',
      name: 'Incident Types',
      component: () => import(/* webpackChunkName: "admin-incident-types" */ '../views/Admin/Settings/IncidentTypes.vue')
    },
    {
      path: 'emergency-types',
      name: 'Emergency Types',
      component: () => import(/* webpackChunkName: "admin-emergency-types" */ '../views/Admin/Settings/EmergencyTypes.vue')
    },
    {
      path: 'incident-type-groups',
      name: 'Incident Type Groups',
      component: () => import(/* webpackChunkName: "admin-incident-type-groups" */ '../views/Admin/Settings/IncidentTypeGroups.vue')
    },
    {
      path: 'managed-groups',
      name: 'Managed Groups',
      component: () => import(/* webpackChunkName: "admin-managed-groups" */ '../views/Admin/ManagedGroups.vue')
    },
    // Guidance
    {
      path: 'guidance/topics',
      name: 'Topics',
      component: () => import(/* webpackChunkName: "admin-guidance-topics" */ '../views/Admin/Guidance/Topics.vue')
    },
    {
      path: 'guidance/articles',
      name: 'Articles',
      component: () => import(/* webpackChunkName: "admin-guidance-articles" */ '../views/Admin/Guidance/Articles.vue')
    },
    {
      path: 'guidance/help-videos',
      name: 'Help Videos',
      component: () => import(/* webpackChunkName: "admin-guidance-help-videos" */ '../views/Admin/Guidance/HelpVideos.vue')
    },
    {
      path: 'guidance/playbooks',
      name: 'Playbooks',
      component: () => import(/* webpackChunkName: "admin-playbooks" */ '../views/Admin/Guidance/Playbooks.vue')
    },
    // Templates
    {
      path: 'templates/announcement-templates',
      name: 'Announcement Templates',
      component: () => import(/* webpackChunkName: "admin-announcement-templates" */ '../views/Admin/Settings/AnnouncementTemplates.vue')
    },
    {
      path: 'templates/locations',
      name: 'Locations',
      component: () => import(/* webpackChunkName: "admin-locations" */ '../views/Admin/Settings/Locations.vue')
    },
    {
      path: 'templates/quick-messages',
      name: 'Quick Messages',
      component: () => import(/* webpackChunkName: "admin-quick-messages" */ '../views/Admin/Settings/QuickMessages.vue')
    },
    {
      path: 'templates/quick-incident-responses',
      name: 'Quick Incident Responses',
      component: () => import(/* webpackChunkName: "admin-quick-incident-responses" */ '../views/Admin/Settings/QuickIncidentResponses.vue')
    },
    // User Management
    {
      path: 'users/invite',
      name: 'Invite New Users',
      component: () => import(/* webpackChunkName: "admin-invite-users" */ '../views/Admin/Users/Invite.vue')
    },
    {
      path: 'users/imports',
      name: 'Import Users',
      component: () => import(/* webpackChunkName: "admin-user-imports" */ '../views/Admin/Users/Imports.vue')
    },
    {
      path: 'users/manage',
      name: 'Manage Users',
      component: () => import(/* webpackChunkName: "admin-manage-users" */ '../views/Admin/Users/Manage.vue')
    },
    // Reporting
    {
      path: 'reporting',
      name: 'Reports',
      component: () => import(/* webpackChunkName: "admin-reporting" */ '../views/Admin/Reporting.vue')
    },
    // Incidents
    {
      path: 'incidents',
      name: 'Open Incidents',
      component: () => import(/* webpackChunkName: "admin-incidents" */ '../views/Admin/Incidents.vue')
    },
    {
      path: 'archived-incidents',
      name: 'Closed Incidents',
      component: () => import(/* webpackChunkName: "admin-archived-incidents" */ '../views/Admin/ArchivedIncidents.vue')
    },
    // Announcements
    {
      path: 'announcements',
      name: 'Announcements',
      component: () => import(/* webpackChunkName: "admin-announcements" */ '../views/Admin/Announcements.vue')
    },
    // Technical Setup
    {
      path: 'settings/windows-installs',
      name: 'Window Installs',
      component: () => import(/* webpackChunkName: "admin-windows-installs" */ '../views/Admin/Settings/WindowsInstalls.vue')
    },
    {
      path: 'integration/smart-buttons',
      name: 'Smart Buttons',
      component: () => import(/* webpackChunkName: "admin-smart-buttons" */ '../views/Admin/Integration/SmartButtons.vue')
    },
    {
      path: 'integration/partners',
      name: 'Partners',
      component: () => import(/* webpackChunkName: "admin-partners" */ '../views/Admin/Integration/Partners.vue')
    },
    {
      path: 'integration/api-clients',
      name: 'API Clients',
      component: () => import(/* webpackChunkName: "admin-api-clients" */ '../views/Admin/Integration/ApiClients.vue')
    },
    {
      path: 'stats',
      name: 'Stats',
      component: () => import(/* webpackChunkName: "admin-stats" */ '../views/Admin/Stats.vue')
    },
    {
      path: 'managed-groups',
      name: 'Managed Groups',
      component: () => import(/* webpackChunkName: "admin-managed-groups" */ '../views/Admin/ManagedGroups.vue')
    },
    {
      path: 'managed-groups/create',
      name: 'Create Managed Group',
      component: () => import(/* webpackChunkName: "admin-create-managed-group" */ '../views/Admin/ManagedGroups/Create.vue')
    },
    {
      path: 'managed-groups/:id/edit',
      name: 'Edit Managed Group',
      component: () => import(/* webpackChunkName: "admin-edit-managed-group" */ '../views/Admin/ManagedGroups/Edit.vue')
    },
    {
      path: 'announcements',
      name: 'Announcements',
      component: () => import(/* webpackChunkName: "admin-announcements" */ '../views/Admin/Announcements.vue')
    },
    {
      path: 'incidents',
      name: 'Incidents',
      component: () => import(/* webpackChunkName: "admin-incidents" */ '../views/Admin/Incidents.vue')
    },
    {
      path: 'incidents/:tenantId/:id',
      name: 'View Incident',
      component: () => import(/* webpackChunkName: "admin-view-incident" */ '../views/Admin/Incidents/Fetch.vue')
    },
    {
      path: 'archived-incidents',
      name: 'Archived Incidents',
      component: () => import(/* webpackChunkName: "admin-archived-incidents" */ '../views/Admin/ArchivedIncidents.vue')
    },
    {
      path: 'responders',
      name: 'Responders',
      component: () => import(/* webpackChunkName: "admin-responders" */ '../views/Admin/Responders.vue')
    },
    {
      path: 'responders/response-teams',
      name: 'Response Teams',
      component: () => import(/* webpackChunkName: "admin-responders-response-teams" */ '../views/Admin/Responders/ResponseTeams.vue')
    },
    {
      path: 'responders/response-teams/create',
      name: 'Create Response Team',
      component: () => import(/* webpackChunkName: "admin-responders-create-response-team" */ '../views/Admin/Responders/ResponseTeams/Create.vue')
    },
    {
      path: 'responders/response-teams/:id',
      name: 'View Response Team',
      component: () => import(/* webpackChunkName: "admin-responders-view-response-team" */ '../views/Admin/Responders/ResponseTeams/Show.vue')
    },
    {
      path: 'responders/response-teams/:id/edit',
      name: 'Edit Response Team',
      component: () => import(/* webpackChunkName: "admin-responders-edit-response-team" */ '../views/Admin/Responders/ResponseTeams/Edit.vue')
    },
    {
      path: 'rota-dates',
      name: 'Rota Dates',
      component: () => import(/* webpackChunkName: "admin-rota-dates" */ '../views/Admin/RotaDates/Index.vue')
    },
    {
      path: 'rota-dates/create',
      name: 'Create Rota Date',
      component: () => import(/* webpackChunkName: "admin-create-rota-date" */ '../views/Admin/RotaDates/Create.vue')
    },
    {
      path: 'responders/availability',
      name: 'Responders Availability',
      component: () => import(/* webpackChunkName: "admin-responders-availability" */ '../views/Admin/Responders/Availability.vue')
    },
    {
      path: 'responders/availability/:presenceId/rota',
      name: 'Responders - Availability - Rota',
      component: () => import(/* webpackChunkName: "admin-responders-availability-rota" */ '../views/Admin/Responders/Availability/Rota.vue')
    },
    {
      path: 'responders/availability/:presenceId/rota/create',
      name: 'Responders - Availability - Rota - Create',
      component: () => import(/* webpackChunkName: "admin-responders-availability-rota-create" */ '../views/Admin/Responders/Availability/Rota/Create.vue')
    },
    {
      path: 'responders/availability/:presenceId/exceptions',
      name: 'Responders - Availability - Exceptions',
      component: () => import(/* webpackChunkName: "admin-responders-availability-exceptions" */ '../views/Admin/Responders/Availability/Exceptions.vue')
    },
    {
      path: 'responders/availability/:presenceId/exceptions/create',
      name: 'Responders - Availability - Exceptions - Create',
      component: () => import(/* webpackChunkName: "admin-responders-availability-exceptions-create" */ '../views/Admin/Responders/Availability/Exceptions/Create.vue')
    },
    {
      path: 'responders/availability/:presenceId/exceptions/quick-create',
      name: 'Responders - Availability - Exceptions - Quick Create',
      component: () => import(/* webpackChunkName: "admin-responders-availability-exceptions-create" */ '../views/Admin/Responders/Availability/Exceptions/QuickCreate.vue')
    },
    {
      path: 'report-by-tag',
      name: 'Report By Tag',
      component: () => import(/* webpackChunkName: "admin-report-by-tag" */ '../views/Admin/ReportByTag.vue')
    },
    {
      path: 'reporting',
      name: 'Reporting',
      component: () => import(/* webpackChunkName: "admin-archived-incidents" */ '../views/Admin/Reporting.vue')
    },
    {
      path: 'reporting/create',
      name: 'Create Report',
      component: () => import(/* webpackChunkName: "admin-create-report" */ '../views/Admin/Reporting/Create.vue')
    },
    {
      path: 'integration',
      name: 'Admin Integration',
      component: () => import(/* webpackChunkName: "admin-integration" */ '../views/Admin/Integration.vue')
    },
    {
      path: 'integration/smart-buttons',
      name: 'Smart Buttons',
      component: () => import(/* webpackChunkName: "admin-smart-buttons" */ '../views/Admin/Integration/SmartButtons.vue')
    },
    {
      path: 'integration/smart-buttons/create',
      name: 'Create Smart Button',
      component: () => import(/* webpackChunkName: "admin-create-smart-button" */ '../views/Admin/Integration/SmartButtons/Create.vue')
    },
    {
      path: 'integration/smart-buttons/:id',
      name: 'View Smart Button',
      component: () => import(/* webpackChunkName: "admin-view-smart-button" */ '../views/Admin/Integration/SmartButtons/Show.vue')
    },
    {
      path: 'integration/smart-buttons/:id/edit',
      name: 'Edit Smart Button',
      component: () => import(/* webpackChunkName: "admin-edit-smart-button" */ '../views/Admin/Integration/SmartButtons/Edit.vue')
    },
    {
      path: 'integration/api-clients',
      name: 'API Clients',
      component: () => import(/* webpackChunkName: "admin-api-clients" */ '../views/Admin/Integration/ApiClients.vue')
    },
    {
      path: 'integration/api-clients/create',
      name: 'Create API Client',
      component: () => import(/* webpackChunkName: "create-api-client" */ '../views/Admin/Integration/ApiClients/Create.vue')
    },
    {
      path: 'integration/api-clients/:id/edit',
      name: 'Edit API Client',
      component: () => import(/* webpackChunkName: "admin-edit-api-client" */ '../views/Admin/Integration/ApiClients/Edit.vue')
    },
    {
      path: 'integration/partners',
      name: 'Partners',
      component: () => import(/* webpackChunkName: "admin-partners" */ '../views/Admin/Integration/Partners.vue')
    },
    {
      path: 'integration/partners/create',
      name: 'Review Partner Integrations',
      component: () => import(/* webpackChunkName: "create-partner-integration" */ '../views/Admin/Integration/Partners/Create.vue')
    },
    {
      path: 'integration/partners/create/:id',
      name: 'Admin - Review Partner Integration',
      component: () => import(/* webpackChunkName: "review-partner-integration" */ '../views/Admin/Integration/Partners/Create/ReviewIntegration.vue')
    },
    {
      path: 'integration/partners/:id',
      name: 'Admin - View Partner',
      component: () => import(/* webpackChunkName: "admin-view-partner" */ '../views/Admin/Integration/Partners/Show.vue')
    },
    {
      path: 'integration/partners/:id/settings',
      name: 'Edit Partner Settings',
     component: () => import(/* webpackChunkName: "admin-edit-partner-settings" */ '../views/Admin/Integration/Partners/EditSettings.vue')
    },
    {
      path: 'guidance',
      name: 'Guidance',
      component: () => import(/* webpackChunkName: "admin-guidance" */ '../views/Admin/Guidance.vue')
    },
    {
      path: 'guidance/topics',
      name: 'Guidance - Topics',
      component: () => import(/* webpackChunkName: "admin-guidance-topics" */ '../views/Admin/Guidance/Topics.vue')
    },
    {
      path: 'guidance/topics/create',
      name: 'Guidance - Create Topic',
      component: () => import(/* webpackChunkName: "admin-guidance-create-topic" */ '../views/Admin/Guidance/Topics/Create.vue')
    },
    {
      path: 'guidance/topics/:id',
      name: 'Guidance - View Topic',
      component: () => import(/* webpackChunkName: "admin-guidance-view-topic" */ '../views/Admin/Guidance/Topics/Show.vue')
    },
    {
      path: 'guidance/topics/:id/edit',
      name: 'Guidance - Edit Topic',
      component: () => import(/* webpackChunkName: "admin-guidance-edit-topic" */ '../views/Admin/Guidance/Topics/Edit.vue')
    },
    {
      path: 'guidance/articles',
      name: 'Guidance - Articles',
      component: () => import(/* webpackChunkName: "admin-guidance-articles" */ '../views/Admin/Guidance/Articles.vue')
    },
    {
      path: 'guidance/articles/create',
      name: 'Guidance - Create Article',
      component: () => import(/* webpackChunkName: "admin-guidance-create-article" */ '../views/Admin/Guidance/Articles/Create.vue')
    },
    {
      path: 'guidance/articles/:id',
      name: 'Guidance - View Article',
      component: () => import(/* webpackChunkName: "admin-guidance-view-article" */ '../views/Admin/Guidance/Articles/Show.vue')
    },
    {
      path: 'guidance/articles/:id/edit',
      name: 'Guidance - Edit Article',
      component: () => import(/* webpackChunkName: "admin-guidance-edit-article" */ '../views/Admin/Guidance/Articles/Edit.vue')
    },
    {
      path: 'guidance/help-videos',
      name: 'Guidance - Help Videos',
      component: () => import(/* webpackChunkName: "admin-guidance-help-videos" */ '../views/Admin/Guidance/HelpVideos.vue')
    },
    {
      path: 'guidance/help-videos/create',
      name: 'Guidance - Create Help Video',
      component: () => import(/* webpackChunkName: "admin-guidance-create-help-video" */ '../views/Admin/Guidance/HelpVideos/Create.vue')
    },
    {
      path: 'guidance/help-videos/:id',
      name: 'Guidance - View Help Video',
      component: () => import(/* webpackChunkName: "admin-guidance-view-help-video" */ '../views/Admin/Guidance/HelpVideos/Show.vue')
    },
    {
      path: 'guidance/help-videos/:id/edit',
      name: 'Guidance - Edit Help Video',
      component: () => import(/* webpackChunkName: "admin-guidance-edit-help-video" */ '../views/Admin/Guidance/HelpVideos/Edit.vue')
    },
    {
      path: 'guidance/playbooks',
      name: 'Guidance - Playbooks',
      component: () => import(/* webpackChunkName: "admin-playbooks" */ '../views/Admin/Guidance/Playbooks.vue')
    },
    {
      path: 'guidance/playbooks/create',
      name: 'Guidance - Create Playbook',
      component: () => import(/* webpackChunkName: "admin-create-playbook" */ '../views/Admin/Guidance/Playbooks/Create.vue')
    },
    {
      path: 'guidance/playbooks/:id',
      name: 'Guidance - View Playbook',
      component: () => import(/* webpackChunkName: "admin-view-playbook" */ '../views/Admin/Guidance/Playbooks/Show.vue')
    },
    {
      path: 'guidance/playbooks/:id/edit',
      name: 'Guidance - Edit Playbook',
      component: () => import(/* webpackChunkName: "admin-edit-playbook" */ '../views/Admin/Guidance/Playbooks/Edit.vue')
    },
    {
      path: 'guidance/downloads',
      name: 'Guidance - Downloads',
      component: () => import(/* webpackChunkName: "admin-downloads" */ '../views/Admin/Guidance/Downloads.vue')
    },
    {
      path: 'guidance/downloads/create',
      name: 'Guidance - Create Download',
      component: () => import(/* webpackChunkName: "admin-create-download" */ '../views/Admin/Guidance/Downloads/Create.vue')
    },
    {
      path: 'guidance/downloads/:id',
      name: 'Guidance - View Download',
      component: () => import(/* webpackChunkName: "admin-guidance-view-download" */ '../views/Admin/Guidance/Downloads/Show.vue')
    },
    {
      path: 'guidance/downloads/:id/edit',
      name: 'Guidance - Edit Download',
      component: () => import(/* webpackChunkName: "admin-guidance-edit-download" */ '../views/Admin/Guidance/Downloads/Edit.vue')
    },
    {
      path: 'guidance/external-links',
      name: 'Guidance - External Links',
      component: () => import(/* webpackChunkName: "admin-external-links" */ '../views/Admin/Guidance/ExternalLinks.vue')
    },
    {
      path: 'guidance/external-links/create',
      name: 'Guidance - Create External Link',
      component: () => import(/* webpackChunkName: "admin-guidance-create-external-link" */ '../views/Admin/Guidance/ExternalLinks/Create.vue')
    },
    {
      path: 'guidance/external-links/:id',
      name: 'Guidance - View External Link',
      component: () => import(/* webpackChunkName: "admin-view-external-link" */ '../views/Admin/Guidance/ExternalLinks/Show.vue')
    },
    {
      path: 'guidance/external-links/:id/edit',
      name: 'Guidance - Edit External Link',
      component: () => import(/* webpackChunkName: "admin-edit-external-link" */ '../views/Admin/Guidance/ExternalLinks/Edit.vue')
    },
    {
      path: 'users',
      name: 'Users',
      component: () => import(/* webpackChunkName: "admin-users" */ '../views/Admin/Users.vue')
    },
    {
      path: 'users/invite',
      name: 'Invite Users',
      component: () => import(/* webpackChunkName: "admin-invite-users" */ '../views/Admin/Users/Invite.vue')
    },
    {
      path: 'users/imports',
      name: 'User Imports',
      component: () => import(/* webpackChunkName: "admin-user-imports" */ '../views/Admin/Users/Imports.vue')
    },
    {
      path: 'users/imports/create',
      name: 'Create User Import',
      component: () => import(/* webpackChunkName: "admin-create-user-import" */ '../views/Admin/Users/Imports/Create.vue')
    },
    {
      path: 'users/manage',
      name: 'Users - Manage',
      component: () => import(/* webpackChunkName: "admin-manage-users" */ '../views/Admin/Users/Manage.vue')
    },
    {
      path: 'users/manage/email',
      name: 'Users - Email Invites',
      component: () => import(/* webpackChunkName: "admin-manage-users-email-user" */ '../views/Admin/Users/Manage/EmailInvites.vue')
    },
    {
      path: 'users/manage/create',
      name: 'Users - Create User',
      component: () => import(/* webpackChunkName: "admin-manage-users-create-user" */ '../views/Admin/Users/Manage/Create.vue')
    },
    {
      path: 'users/manage/:id',
      name: 'Manage Users - View User',
      component: () => import(/* webpackChunkName: "admin-manage-users-view-user" */ '../views/Admin/Users/Manage/Show.vue')
    },
    {
      path: 'users/manage/:id/edit',
      name: 'Manage Users - Edit User',
      component: () => import(/* webpackChunkName: "admin-manage-users-edit-user" */ '../views/Admin/Users/Manage/Edit.vue')
    },
    {
      path: 'settings',
      name: 'Settings',
      component: () => import(/* webpackChunkName: "admin-settings" */ '../views/Admin/Settings.vue')
    },
    {
      path: 'settings/templates',
      name: 'Settings - Templates',
      component: () => import(/* webpackChunkName: "admin-settings-templates" */ '../views/Admin/Settings/Templates.vue')
    },
    {
      path: 'settings/windows-installs',
      name: 'Settings - Windows Installs',
      component: () => import(/* webpackChunkName: "admin-settings-windows-installs" */ '../views/Admin/Settings/WindowsInstalls.vue')
    },
    {
      path: 'settings/quick-incident-responses',
      name: 'Settings - Quick Incident Responses',
      component: () => import(/* webpackChunkName: "admin-settings-quick-incident-responses" */ '../views/Admin/Settings/QuickIncidentResponses.vue')
    },
    {
      path: 'settings/quick-incident-responses/create',
      name: 'Settings - Create Quick Incident Response',
      component: () => import(/* webpackChunkName: "admin-settings-create-quick-incident-response" */ '../views/Admin/Settings/QuickIncidentResponses/Create.vue')
    },
    {
      path: 'settings/quick-incident-responses/:id/edit',
      name: 'Settings - Edit Quick Incident Response',
      component: () => import(/* webpackChunkName: "admin-settings-edit-quick-incident-response" */ '../views/Admin/Settings/QuickIncidentResponses/Edit.vue')
    },
    {
      path: 'settings/quick-messages',
      name: 'Settings - Quick Messages',
      component: () => import(/* webpackChunkName: "admin-settings-quick-messages" */ '../views/Admin/Settings/QuickMessages.vue')
    },
    {
      path: 'settings/quick-messages/create',
      name: 'Settings - Create Quick Message',
      component: () => import(/* webpackChunkName: "admin-settings-create-quick-message" */ '../views/Admin/Settings/QuickMessages/Create.vue')
    },
    {
      path: 'settings/quick-messages/:id/edit',
      name: 'Settings - Edit Quick Message',
      component: () => import(/* webpackChunkName: "admin-settings-edit-quick-message" */ '../views/Admin/Settings/QuickMessages/Edit.vue')
    },
    {
      path: 'settings/announcement-templates',
      name: 'Settings - Announcement Templates',
      component: () => import(/* webpackChunkName: "admin-settings-announcement-templates" */ '../views/Admin/Settings/AnnouncementTemplates.vue')
    },
    {
      path: 'settings/announcement-templates/create',
      name: 'Settings - Create Announcement Template',
      component: () => import(/* webpackChunkName: "admin-settings-create-announcement-template" */ '../views/Admin/Settings/AnnouncementTemplates/Create.vue')
    },
    {
      path: 'settings/announcement-templates/:id/edit',
      name: 'Settings - Edit Announcement Template',
      component: () => import(/* webpackChunkName: "admin-settings-edit-announcement-template" */ '../views/Admin/Settings/AnnouncementTemplates/Edit.vue')
    },
    {
      path: 'settings/playbooks',
      name: 'Settings - Playbooks',
      component: () => import(/* webpackChunkName: "admin-settings-playbooks" */ '../views/Admin/Settings/Playbooks.vue')
    },
    {
      path: 'settings/playbooks/create',
      name: 'Settings - Create Playbook',
      component: () => import(/* webpackChunkName: "admin-settings-create-playbook" */ '../views/Admin/Settings/Playbooks/Create.vue')
    },
    {
      path: 'settings/playbooks/:id',
      name: 'Settings - View Playbook',
      component: () => import(/* webpackChunkName: "admin-settings-view-playbook" */ '../views/Admin/Settings/Playbooks/Show.vue')
    },
    {
      path: 'settings/playbooks/:id/edit',
      name: 'Settings - Edit Playbook',
      component: () => import(/* webpackChunkName: "admin-settings-edit-playbook" */ '../views/Admin/Settings/Playbooks/Edit.vue')
    },
    {
      path: 'settings/locations',
      name: 'Settings - Locations',
      component: () => import(/* webpackChunkName: "admin-settings-locations" */ '../views/Admin/Settings/Locations.vue')
    },
    {
      path: 'settings/locations/create',
      name: 'Settings - Create Location',
      component: () => import(/* webpackChunkName: "admin-settings-create-location" */ '../views/Admin/Settings/Locations/Create.vue')
    },
    {
      path: 'settings/locations/:id/edit',
      name: 'Settings - Edit Location',
      component: () => import(/* webpackChunkName: "admin-settings-edit-location" */ '../views/Admin/Settings/Locations/Edit.vue')
    },
    {
      path: 'settings/incident-type-groups',
      name: 'Settings - Incident Type Groups',
      component: () => import(/* webpackChunkName: "admin-settings-incident-type-groups" */ '../views/Admin/Settings/IncidentTypeGroups.vue')
    },
    {
      path: 'settings/incident-type-groups/:id/edit',
      name: 'Settings - Edit Incident Type Group',
      component: () => import(/* webpackChunkName: "admin-settings-edit-incident-type-group" */ '../views/Admin/Settings/IncidentTypeGroups/Edit.vue')
    },
    {
      path: 'settings/incident-type-groups/create',
      name: 'Settings - Create Incident Type Group',
      component: () => import(/* webpackChunkName: "admin-settings-create-incident-type-group" */ '../views/Admin/Settings/IncidentTypeGroups/Create.vue')
    },
    {
      path: 'settings/incident-types',
      name: 'Settings - Incident Types',
      component: () => import(/* webpackChunkName: "admin-settings-incident-types" */ '../views/Admin/Settings/IncidentTypes.vue')
    },
    {
      path: 'settings/incident-types/create',
      name: 'Settings - Create Incident Type',
      component: () => import(/* webpackChunkName: "admin-settings-create-incident-type" */ '../views/Admin/Settings/IncidentTypes/Create.vue')
    },
    {
      path: 'settings/incident-types/:id',
      name: 'Settings - Show Incident Type',
      component: () => import(/* webpackChunkName: "admin-settings-show-incident-type" */ '../views/Admin/Settings/IncidentTypes/Show.vue')
    },
    {
      path: 'settings/incident-types/:id/edit',
      name: 'Settings - Edit Incident Type',
      component: () => import(/* webpackChunkName: "admin-settings-edit-incident-type" */ '../views/Admin/Settings/IncidentTypes/Edit.vue')
    },
    {
      path: 'settings/emergency-types',
      name: 'Settings - Emergency Types',
      component: () => import(/* webpackChunkName: "admin-settings-emergency-types" */ '../views/Admin/Settings/EmergencyTypes.vue')
    },
    {
      path: 'settings/emergency-types/create',
      name: 'Admin - Settings - Create Emergency Type',
      component: () => import(/* webpackChunkName: "admin-settings-create-emergency-type" */ '../views/Admin/Settings/EmergencyTypes/Create.vue')
    },
    {
      path: 'settings/emergency-types/:id/edit',
      name: 'Settings - Edit Emergency Type',
      component: () => import(/* webpackChunkName: "admin-settings-edit-emergency-type" */ '../views/Admin/Settings/EmergencyTypes/Edit.vue')
    },
    {
      path: 'organisational-settings',
      name: 'Organisational Settings',
      beforeEnter() {
        let env = store.getters['missionControl/env'];
        if (env == 'live') {
          window.open("https://portal.teamsos.co.uk/app");
        }
        if (env == 'staging') {
          window.open("https://portal.teamsos.co.uk/app");
        }
        if (env == 'testing') {
          window.open("https://portal.testing.teamsos.co.uk/app");
        }
      }
    }
  ]
}

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },

  {
    path: '/logged-out',
    name: 'Logged Out',
    component: LoggedOut
  },

  {
    path: '/sims-login',
    name: 'SIMS Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-login" */ '../views/SimsLogin.vue')
  },

  {
    path: '/landings/sims',
    name: 'SIMS Landing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-landing" */ '../views/Landings/sims.vue')
  },

  {
    path: '/onboarding',
    name: 'Onboarding',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding.vue')
  },

  // Senso
  {
    path: '/senso',
    name: 'Senso',
    component: () => import(/* webpackChunkName: "senso-dashboard" */ '../views/Senso/Dashboard.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/lockdown',
    name: 'Senso Lockdown',
    component: () => import(/* webpackChunkName: "senso-lockdown" */ '../views/Senso/Lockdown.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/announcements',
    name: 'Senso Announcements',
    component: () => import(/* webpackChunkName: "senso-announcements" */ '../views/Senso/Announcements.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/announcements/:tenantId/:id',
    name: 'Senso View Announcement',
    component: () => import(/* webpackChunkName: "senso-show-announcement" */ '../views/Senso/Announcements/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/incidents',
    name: 'Senso Incidents',
    component: () => import(/* webpackChunkName: "senso-incidents" */ '../views/Senso/Incidents.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/incidents/:tenantId/:id',
    name: 'Senso View Incident',
    component: () => import(/* webpackChunkName: "senso-show-incident" */ '../views/Senso/Incidents/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/guidance',
    name: 'Senso Guidance',
    component: () => import(/* webpackChunkName: "senso-guidance" */ '../views/Senso/Guidance.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },

  // Lightspeed
  {
    path: '/lightspeed',
    name: 'Lightspeed',
    component: () => import(/* webpackChunkName: "lightspeed-dashboard" */ '../views/Lightspeed/Dashboard.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/lockdown',
    name: 'Lightspeed Lockdown',
    component: () => import(/* webpackChunkName: "lightspeed-lockdown" */ '../views/Lightspeed/Lockdown.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/announcements',
    name: 'Lightspeed Announcements',
    component: () => import(/* webpackChunkName: "lightspeed-announcements" */ '../views/Lightspeed/Announcements.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/announcements/:tenantId/:id',
    name: 'Lightspeed View Announcement',
    component: () => import(/* webpackChunkName: "lightspeed-show-announcement" */ '../views/Lightspeed/Announcements/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/incidents',
    name: 'Lightspeed Incidents',
    component: () => import(/* webpackChunkName: "lightspeed-incidents" */ '../views/Lightspeed/Incidents.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/call-for-help',
    name: 'Lightspeed Call For Help',
    component: () => import(/* webpackChunkName: "lightspeed-call-for-help" */ '../views/Lightspeed/CallForHelp.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/incidents/:tenantId/:id',
    name: 'Lightspeed View Incident',
    component: () => import(/* webpackChunkName: "lightspeed-show-incident" */ '../views/Lightspeed/Incidents/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/guidance',
    name: 'Lightspeed Guidance',
    component: () => import(/* webpackChunkName: "lightspeed-guidance" */ '../views/Lightspeed/Guidance.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/guidance/:tenantId/:id',
    name: 'Lightspeed Guidance Pack',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lightspeed-guidance-pack" */ '../views/Lightspeed/Guidance/Show.vue')
  },

  // Sims
  {
    path: '/sims',
    name: 'SIMS',
    component: () => import(/* webpackChunkName: "sims" */ '../views/sims/Sims.vue')
  },

  // Sims Simualtor
  {
    path: '/sims-simulator',
    name: 'Sims Simulator',
    component: () => import(/* webpackChunkName: "sims-simulator" */ '../views/sims/SimsSimulator.vue')
  },

  // SIMS Demo
  {
    path: '/sims-demo',
    name: 'sims-demo',
    component: () => import(/* webpackChunkName: "sims-dashboard" */ '../views/sims/Dashboard.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/lockdown',
    name: 'SIMS Lockdown',
    component: () => import(/* webpackChunkName: "sims-lockdown" */ '../views/sims/Lockdown.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/announcements',
    name: 'SIMS Announcements',
    component: () => import(/* webpackChunkName: "sims-announcements" */ '../views/sims/Announcements.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/announcements/:tenantId/:id',
    name: 'SIMS View Announcement',
    component: () => import(/* webpackChunkName: "sims-show-announcement" */ '../views/sims/Announcements/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/chats',
    name: 'SIMS Chats',
    component: () => import(/* webpackChunkName: "sims-chats" */ '../views/sims/Chats.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/chats/:tenantId/:id',
    name: 'SIMS View Chat',
    component: () => import(/* webpackChunkName: "sims-show-chat" */ '../views/sims/Chats/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/incidents',
    name: 'SIMS Incidents',
    component: () => import(/* webpackChunkName: "sims-incidents" */ '../views/sims/Incidents.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/call-for-help',
    name: 'SIMS Call For Help',
    component: () => import(/* webpackChunkName: "sims-call-for-help" */ '../views/sims/CallForHelp.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/incidents/:tenantId/:id',
    name: 'SIMS View Incident',
    component: () => import(/* webpackChunkName: "sims-show-incident" */ '../views/sims/Incidents/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/guidance',
    name: 'SIMS Guidance',
    component: () => import(/* webpackChunkName: "sims-guidance" */ '../views/sims/Guidance.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/guidance/:tenantId/:id',
    name: 'SIMS Guidance Pack',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-guidance-pack" */ '../views/sims/Guidance/Show.vue')
  },

  {
    path: '/guest-onboarding-old',
    name: 'Guest Onboarding Landing Old',
    meta: {
      // authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guest-onboarding-landing-old" */ '../views/GuestOnboarding/LandingOld.vue')
  },

  // Guest Onboarding
  {
    path: '/guest-onboarding',
    name: 'Guest Onboarding Landing',
    meta: {
      // authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "guest-onboarding-landing" */ '../views/GuestOnboarding/Landing.vue')
    component: () => import(/* webpackChunkName: "guest-onboarding-calendar" */ '../views/GuestOnboarding/LandingCalendar.vue')
  },

  // Guest Onboarding - Create Account
  {
    path: '/guest-onboarding/create-account',
    name: 'Guest Onboarding - Create Account',
    meta: {
      // authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guest-onboarding-create-account" */ '../views/GuestOnboarding/CreateAccount.vue')
  },

  // Guest Onboarding - Create Org
  {
    path: '/guest-onboarding/create-org',
    name: 'Guest Onboarding - Create Org',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guest-onboarding-create-org" */ '../views/GuestOnboarding/CreateOrg.vue')
  },

  // Sims alias
  // Guest Onboarding
  {
    path: '/sims-onboarding',
    name: 'SIMS Onboarding Landing',
    meta: {
      // authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-onboarding-landing" */ '../views/SimsOnboarding/Landing.vue')
  },

  // Guest Onboarding - Create Account
  {
    path: '/sims-onboarding/create-account',
    name: 'SIMS Onboarding - Create Account',
    meta: {
      // authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-onboarding-create-account" */ '../views/SimsOnboarding/CreateAccount.vue')
  },

  // Guest Onboarding - Create Org
  {
    path: '/sims-onboarding/create-org',
    name: 'Sims Onboarding - Create Org',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-onboarding-create-org" */ '../views/SimsOnboarding/CreateOrg.vue')
  },

  // Normal

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/organisation-not-linked-to-account',
    name: 'Organisation Not Linked To Account',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "organisation-not-linked-to-account" */ '../views/OrganisationNotLinkedToAccount.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/chats',
    name: 'Chats',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "chats" */ '../views/Chats.vue')
  },
  {
    path: '/chats/:tenantId/:id',
    name: 'View Chat',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "view-chat" */ '../views/Chats/Show.vue')
  },
  {
    path: '/incidents',
    name: 'Incidents',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "incidents" */ '../views/Incidents.vue')
  },
  {
    path: '/closed-incidents',
    name: 'Closed Incidents',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "closed-incidents" */ '../views/ClosedIncidents.vue')
  },
  {
    path: '/incidents/:tenantId/:id',
    name: 'View Incident',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "view-incident" */ '../views/Incidents/Show.vue')
  },
  {
    path: '/my-tasks',
    name: 'My Tasks',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tasks" */ '../views/Tasks.vue')
  },
  {
    path: '/guidance',
    name: 'Guidance',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidance" */ '../views/Guidance.vue')
  },
  {
    path: '/guidance/:tenantId/:id',
    name: 'Guidance Pack',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidance-pack" */ '../views/Guidance/Show.vue')
  },
  {
    path: '/announcements',
    name: 'Announcements',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "announcements" */ '../views/Announcements.vue')
  },
  {
    path: '/announcements/:tenantId/:id',
    name: 'View Announcement',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "view-announcement" */ '../views/Announcements/Show.vue')
  },
  {
    path: '/start-a-drill',
    name: 'Start A Drill',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "start-a-drill" */ '../views/StartADrill.vue')
  },
  {
    path: '/call-for-help',
    name: 'Call For Help',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "call-for-help" */ '../views/CallForHelp.vue')
  },
  {
    path: '/faqs',
    name: 'FAQs',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faqs" */ '../views/Faqs.vue')
  },
  adminRoutes,
 
 
  {
    path: '/super-admin/leads',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "super-admin" */ '../views/SuperAdmin/Leads.vue')
  },
  {
    path: '/platform-admin/sso',
    meta: {
      authName: idsrvAuth.authName,
      platformAdmin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "platform-admin-sso" */ '../views/PlatformAdmin/SSO.vue')
  },
  {
    path: '/platform-admin/sso',
    meta: {
      authName: idsrvAuth.authName,
      platformAdmin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "platform-admin-sso" */ '../views/PlatformAdmin/SSO.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Requires non hidden presences guard
// router.beforeEach((to, from, next) => {
//   if (!store.getters['missionControl/isLoggedIn']) {
//     next()
//   }
//   if (to.matched.some(record => record.meta.requiresNonHiddenPresences)) {
//     if (store.getters['missionControl/hasNonHiddenPresences']) {
//       next()
//       return
//     }
//     next('/admin')
//   } else {
//     next()
//   }
// });

// Admin Guard
router.beforeEach((to, from, next) => {
  // if (!store.getters['missionControl/isLoggedIn']) {
  // next()
  // }
  if (to.matched.some(record => record.meta.admin)) {
    if (!store.getters['missionControl/isLoggedIn']) {
      next()
      return
    }
    if (store.getters['missionControl/canViewAdminArea']) {
      next()
      return
    } else {
      next('/')
      return
    }
  } else {
    next()
  }
})


// Platform Admin Guard
router.beforeEach((to, from, next) => {
  // if (!store.getters['missionControl/isLoggedIn']) {
  // next()
  // }
  if (to.matched.some(record => record.meta.platformAdmin)) {
    if (!store.getters['missionControl/isLoggedIn']) {
      next()
      return
    }
    if (store.getters['missionControl/isPlatformAdmin']) {
      next()
      return
    } else {
      next('/')
      return
    }
  } else {
    next()
  }
})

idsrvAuth.useRouter(router)

export default router
