<template>
  <div class="getting-started-item" :class="{ 'completed': isCompleted }">
    <div class="content">
      <div class="step-number">{{ stepNumber }}</div>
      <div class="details">
        <h3>{{ title }}</h3>
        <p>{{ description }}</p>
      </div>
      <div class="action">
        <a-button type="primary" @click="$emit('click')" :disabled="isCompleted">
          {{ isCompleted ? 'Completed' : buttonText }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GettingStartedItem',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      default: 'Get Started'
    },
    stepNumber: {
      type: Number,
      required: true
    },
    isCompleted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.getting-started-item {
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &.completed {
    background: #f6f6f6;
    
    .step-number {
      background: #52c41a;
      color: white;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .step-number {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #1890ff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
  }

  .details {
    flex: 1;

    h3 {
      margin: 0 0 8px;
      font-size: 18px;
      color: #262626;
    }

    p {
      margin: 0;
      color: #595959;
      font-size: 14px;
    }
  }

  .action {
    min-width: 120px;
    text-align: right;
  }
}
</style> 