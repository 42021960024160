<template>
  <div class="admin">
    <div class="header">
      <h1>Getting Started with teamSOS Admin</h1>
      <p class="subtitle">Complete these steps to set up and configure your teamSOS instance</p>
    </div>

    <div class="guide-items">
      <getting-started-item
        v-for="(item, index) in guideItems"
        :key="index"
        :title="item.title"
        :description="item.description"
        :step-number="index + 1"
        :is-completed="item.completed"
        :button-text="item.buttonText"
        @click="handleItemClicked(item)"
      />
    </div>
  </div>
</template>

<script>
import GettingStartedItem from "../components/Admin/GettingStartedItem.vue";

export default {
  components: { GettingStartedItem },
  data() {
    return {
      guideItems: [
        {
          title: "Add Team Members",
          description: "Invite your team members and set up their roles and permissions.",
          buttonText: "Manage Users",
          completed: false,
          onClick: () => {
            this.$router.push("/admin/users");
          }
        },
        {
          title: "Set Up Response Teams",
          description: "Create response teams and assign members to handle incidents.",
          buttonText: "Set Up Teams",
          completed: false,
          onClick: () => {
            this.$router.push("/admin/responders");
          }
        },
        {
          title: "Create Managed Groups",
          description: "Organize your staff into groups for better internal communication.",
          buttonText: "Create Groups",
          completed: false,
          onClick: () => {
            this.$router.push("/admin/managed-groups");
          }
        },
        {
          title: "Set Up Incident Types",
          description: "Create and configure incident types for different scenarios your team handles.",
          buttonText: "Configure Incidents",
          completed: false,
          onClick: () => {
            this.$router.push("/admin/incident-types");
          }
        },
        {
          title: "Set Up Emergency Types",
          description: "Define emergency response protocols and SOS scenarios for critical situations.",
          buttonText: "Configure Emergencies",
          completed: false,
          onClick: () => {
            this.$router.push("/admin/emergency-types");
          }
        },
      ]
    };
  },
  methods: {
    handleItemClicked(item) {
      if (item.onClick) {
        item.onClick();
      }
    }
  },
  // Load completion status from localStorage on mount
  mounted() {
    const savedProgress = localStorage.getItem('adminGuideProgress');
    if (savedProgress) {
      const completedItems = JSON.parse(savedProgress);
      this.guideItems = this.guideItems.map((item, index) => ({
        ...item,
        completed: completedItems[index] || false
      }));
    }
  },
  // Save completion status to localStorage when it changes
  watch: {
    completedSteps() {
      const completedItems = this.guideItems.map(item => item.completed);
      localStorage.setItem('adminGuideProgress', JSON.stringify(completedItems));
    }
  }
};
</script>

<style scoped lang="scss">
.admin {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
}

.header {
  text-align: center;
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    color: #262626;
    margin-bottom: 12px;
  }

  .subtitle {
    font-size: 16px;
    color: #595959;
    margin-bottom: 24px;
  }
}

.guide-items {
  margin-top: 40px;
}
</style>